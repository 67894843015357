<template>
  <div class="order-detail">
    <div class="top">
      <h6>售后详情</h6>
      <div class="back" @click="cancel">返回上一级</div>
    </div>
    <el-form class="demo-form-inline">
      <el-card class="!border-none mt-4" shadow="never">
        <div class="flex mt-[24px]">
          <div class="w-2/5">
            <el-form-item label="退款状态">
              -
            </el-form-item>
            <el-form-item label="售后单号">
              -
            </el-form-item>
            <el-form-item label="售后类型">
              -
            </el-form-item>
            <el-form-item label="退款原因">
              -
            </el-form-item>
            <el-form-item label="退款说明">
              <div class="break-all">
                -
              </div>
            </el-form-item>
          </div>
          <div class="w-3/5">
            <el-form-item label="订单编号">
              -
            </el-form-item>
            <el-form-item label="退款方式">
              -
            </el-form-item>
            <el-form-item label="退款金额">
              <span class="text-[#fd3e47]">
                ¥0
              </span>
              <el-tooltip class="box-item" effect="dark" content="未发货的订单，用户申请售后可以退回运费；已经发货的订单，用户申请售后不退回运费"
                placement="top-start">
                <el-icon size="18px" class="ml-1 text-[#ccc]">
                  <QuestionFilled />
                </el-icon>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="申请时间">
              -
            </el-form-item>
            <el-form-item label="退款凭证">
              -
            </el-form-item>
          </div>
        </div>

        <div class="pt-4" style="border-top: 1px solid #eeeeee">
          <el-button type="primary">
            同意售后
          </el-button>

          <el-button type="danger">
            拒绝售后
          </el-button>

          <el-button type="primary">
            确认收货
          </el-button>

          <el-button type="danger">
            拒绝收货
          </el-button>

          <el-button type="primary">
            同意退款
          </el-button>

          <el-button type="danger">
            拒绝退款
          </el-button>

          <el-button type="primary">
            确认退款
          </el-button>
        </div>
      </el-card>

      <el-card class="!border-none mt-4" shadow="never">
        <div class="pb-4 mb-4 text-lg font-medium" style="border-bottom: 1px solid #eeeeee">
          退款商品
        </div>
        <!-- 商品表格 -->
        <TableView :loading="loading" :columns="columns" :data="rTableData" :pagination="pagination"
          :is-pagination-show="isPaginationShow" @getData="getDataList">
          <template #action>
            <el-table-column label="操作" align="center">
              <template #default="scoped">
                <el-button type="primary" size="small">
                  编辑
                </el-button>
                <el-button type="primary" size="small">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </template>
        </TableView>
      </el-card>

      <el-card class="!border-none mt-4" shadow="never">
        <div class="pb-4 mb-4 text-lg font-medium" style="border-bottom: 1px solid #eeeeee">
          订单日志
        </div>
        <!-- 操作日志表哥 -->
        <el-table size="large">
          <el-table-column prop="operatorUser" label="操作人" min-width="100" />
          <el-table-column prop="content" label="操作内容" min-width="100" />
          <el-table-column prop="create_time" label="记录日期" min-width="100" />
        </el-table>
      </el-card>
    </el-form>
  </div>
</template>

<script setup >
import { reactive, onMounted, toRefs } from 'vue'
import TableView from '@/components/TableView.vue'
import { useRouter } from 'vue-router'
const router = useRouter()
const initData = reactive({
  loading: false,
  isPaginationShow: false,
  rTableData: [],
  columns: [
    {
      index: true,
      indexMethod (index) {
        return index + 1
      },
      width: 70,
      label: '编号'
    },
    { prop: 'nickName', label: '退款原因' },
    { prop: 'phone', label: '退款类型' },
    { prop: 'typeName', label: '状态' },
    { prop: 'currentActiveIntegral', label: '排序' },
    { slot: 'action' }
  ],
})

const {
  loading,
  rTableData,
  columns,
} = toRefs(initData)

// 返回上一级、取消
const cancel = () => {
  router.push('/orderList')
}
</script>
<style lang="scss">
.goodall {
  font-size: 14px;
}
</style>
